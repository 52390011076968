import React from "react";

import "./App.css";
import logo from "./assets/logo.svg";
import comingsoon from "./assets/comingsoon.svg";

const App = () => {
  return (
    <div className="App">
      <div className="gradient__bg">
        <div className="b10x__navbar-links_logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="b10x__navbar-links_coming">
          <img src={comingsoon} alt="coming" />
        </div>
      </div>
    </div>
  );
};

export default App;
